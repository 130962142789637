import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LogHttpInterceptor } from './log-http.interceptor';
import { BusyInterceptor } from './busy.interceptor';
import { HttpErrorInterceptor } from './http-error.interceptor';

export const httpInterceptorProviders = [
	{ provide: HTTP_INTERCEPTORS, useClass: LogHttpInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: BusyInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
];
