import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@shared-services/toast.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

	constructor(private toastService: ToastService) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next
			.handle(request)
			.pipe(
				retry(1),
				catchError((error: HttpErrorResponse) => {
					let errorMessage = '';
					if (error.error instanceof ErrorEvent) {
						// client-side error
						errorMessage = `There was an issue with your request - Error: ${error.error.message}`;
					} else {
						// server-side error
						errorMessage = `There was an issue with your request - Error Code: ${error.status}\nMessage: ${error.message}`;
					}
					this.toastService.show(errorMessage, { classname: 'bg-danger text-light' }); // window.alert(errorMessage);
					return throwError(errorMessage);
				})
			);
	}
}
