import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { OAuthModule } from 'angular-oauth2-oidc';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { httpInterceptorProviders } from '@shared-interceptors/interceptors';
import { SharedComponentsModule } from '@shared-components/shared-components.module';
import { LayoutsModule } from './shared/layouts/layouts.module';

import { environment } from 'src/environments/environment';
import { AuthService } from '@shared-services/auth.service';

import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { FooterComponent } from './footer/footer.component';
import { SharedPipesModule } from '@shared-pipes/shared-pipes.module';

var env: any = environment;

@NgModule({
	declarations: [
		AppComponent,
		NavBarComponent,
		FooterComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		LayoutsModule,
		SharedComponentsModule,
		HttpClientModule,
		NgbModule,
		FormsModule,
		SharedPipesModule,
		OAuthModule.forRoot()
	],
	providers: [
		httpInterceptorProviders,
		{
			provide: APP_INITIALIZER,
			useFactory: initAuth,
			multi: true,
			deps: [AuthService]
		}
	],
	bootstrap: [AppComponent],
})
export class AppModule { }

export function initAuth(authService: AuthService) {
	if (env.production) {
		return () => authService.getProdToken();
	} else {
		return () => authService.getDevToken();
	}
}
