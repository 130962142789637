import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class TimeGuard implements CanActivate {

	constructor(public router: Router) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
		):
			| Observable<boolean | UrlTree>
			| Promise<boolean | UrlTree>
			| boolean
			| UrlTree {
		if (!environment.showTime) {
			this.router.navigate(['where-am-i']);
			return false
		} else {
			return true;
		}
	}
}
