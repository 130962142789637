import { Component, OnInit } from '@angular/core';
import { observeOn } from 'rxjs/operators';
import { asapScheduler } from 'rxjs';
import { BusyService } from '@shared-services/busy.service';
import { UserService } from '@shared-services/user.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
	busy = false;

	constructor(private busyService: BusyService, private userService: UserService) {
		this.busyService.busyState$
			// asapScheduler ensures this is async; remove this and look in console to see nasty error without this
			// ExpressionChangedAfterItHasBeenCheckedError
			.pipe(observeOn(asapScheduler))
			.subscribe((bs) => (this.busy = bs.isBusy));
	}

	ngOnInit() {
		this.userService.initUserStatus();
		this.userService.initUserInfo();
	}
}
