import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
// import { FieldOption } from '../../models/field-option.model';

@Component({
	selector: 'app-field-option',
	templateUrl: './field-option.component.html'
})
export class FieldOptionComponent implements OnInit {
	@Input() options: any[] = [];// : FieldOption[] = [new FieldOption('','')];
	@Input() label: string = 'Status';
	// @Input() index;
	@Output() valueChanged = new EventEmitter<any>();

	constructor() {}

	ngOnInit() {}

	optionSelected(event: any, input: any) {
		this.valueChanged.emit(this.options[event.target.value]);
		setTimeout(() => input.value = 'default', 1000);
	}
}
