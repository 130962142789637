import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { FieldOptionComponent } from './field-option/field-option.component';
import { AlertComponent } from './alert/alert.component';
import { SpinnerComponent } from './spinner/spinner-component';
import { ToastModule } from './toast/toast.module';

@NgModule({
	declarations: [
		AlertComponent,
		SpinnerComponent,
		FieldOptionComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ToastModule
	],
	exports: [
		AlertComponent,
		SpinnerComponent,
		FieldOptionComponent,
		ToastModule
	]
})

export class SharedComponentsModule {}
