<div class="wrapper">
	<app-nav-bar></app-nav-bar>
	<div class="page-body">
		<router-outlet></router-outlet>
	</div>
	<app-footer></app-footer>
</div>
<!-- <div class="background"></div> -->
<!-- For Full Page Static Background -->
<div [hidden]="!busy">
	<app-spinner></app-spinner>
</div>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>
