import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpEvent,
	HttpResponse,
	HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { prefixReq, prefixRes } from './http-config';
// import { StateService } from '@shared-services/state.service';

@Injectable()
export class LogHttpInterceptor implements HttpInterceptor {
	constructor(/*private stateService: StateService*/) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const started = Date.now();
		this.logRequest(req);
		return next.handle(req).pipe(
			tap(
				(event) => this.logResponse(event, req, started),
				(event) => this.logError(event, req, started)
			)
		);
	}

	private logRequest(req: HttpRequest<any>): void {
		console.groupCollapsed(`${prefixReq} Log Http Request`);
		console.log(`${req.method} "${req.urlWithParams}"`);
		console.groupEnd();
	}

	private logResponse(event: HttpEvent<any>, req: HttpRequest<any>, started: number): void {
		if (event instanceof HttpResponse) {
			console.groupCollapsed(`${prefixRes} Log Http Response`);
			const elapsed = Date.now() - started;
			console.log(
				`HTTP: Response for ${req.urlWithParams}\nreturned with status ${event.status}\nand took ${elapsed} ms`
			);
			console.groupEnd();
			// this.stateService.addCallHistory({
			// 	type: 'Success',
			// 	method: req.method,
			// 	url: req.url,
			// 	init: started,
			// 	duration: elapsed,
			// 	status: event.status
			// });
		}
	}

	private logError(event: HttpEvent<any>, req: HttpRequest<any>, started: number ): void {
		if (event instanceof HttpErrorResponse) {
			console.groupCollapsed(`${prefixRes} Log Http Response Error`);
			const elapsed = Date.now() - started;
			console.log(
				`Http Response Error for ${req.urlWithParams}\nreturned with status ${event.status}\nand took ${elapsed} ms`
			);
			console.groupEnd();
			// this.stateService.addCallHistory({
			// 	type: 'Error',
			// 	method: req.method,
			// 	url: req.url,
			// 	init: started,
			// 	duration: elapsed,
			// 	status: event.status
			// });
		}
	}
}
