<ngb-toast
	*ngFor="let toast of toastService.toasts"
	[class]="toast.classname"
	[autohide]="true"
	[delay]="toast.delay || 5000"
	(hidden)="toastService.remove(toast)"
>
	<ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
		<ng-template [ngTemplateOutlet]="toast.textOrTemplate"></ng-template>
	</ng-template>

	<ng-template #text><h6 class="mb-0">{{ toast.textOrTemplate }}</h6></ng-template>
</ngb-toast>
