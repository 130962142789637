<div class="container-fluid footer mt-5">
	<div class="container">
		<div class="row py-5">
			<div class="col">
				<h2 class="mb-2">U.S. Department of Justice - Tax Division</h2>
				<span class="address">
					<p class="mb-2">950 Pennsylvania Avenue, NW</p>
					<p>Washington, DC 20530-0001</p>
				</span>
			</div>
			<div class="col">
				<h2 class="mb-2">Visit Tax Net</h2>
				<div class="address">
					<p class="mb-2"><a href="https://doj365.sharepoint.us/sites/tax-net" target="_blank" rel="noopener">TaxNet</a></p>
				</div>
				<!-- <h2 class="mb-2">Useful Links</h2>
				<div class="address">
					<p class="mb-2"><a href="#" target="_blank">About the New Employee Portal</a></p>
					<p class="mb-2"><a href="#" target="_blank">Suggestions for the Portal?</a></p>
					<p class="mb-2"><a href="#" target="_blank">About Telework Requests</a></p>
					<p class="mb-2"><a href="#" target="_blank">About Time Entry Requirements</a></p>
				</div> -->
			</div>
			<div class="col">
				<h2 class="mb-2">Need Help?</h2>
				<div class="address">
					<p><a href="https://taxdivision.servicenowservices.com/esc?id=ec_dashboard" target="_blank" rel="noopener">Create a Service Now Ticket</a></p>
				</div>
				<h2 class="mb-2">TAXI - Help Desk</h2>
				<div class="address">
					<p class="mb-2">202-616-8294</p>
					<p class="mb-2"><a href="mailto:tax.taxi@usdoj.gov" target="_blank">tax.taxi@usdoj.gov</a></p>
					<p class="mb-2"><a href="https://doj365.sharepoint.us/sites/Tax-IT/SitePages/Help-Desk.aspx" target="_blank" rel="noopener">Helpdesk Information</a></p>
				</div>
				<h2 class="mb-2 mt-3">Application Information</h2>
				<div class="address">
					<p class="mb-2"><a href="#" [routerLink]="['/version-history']">Version History</a></p>
				</div>
			</div>
		</div>
	</div>
</div>

