import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardGuard } from '@shared-guards/dashbaord.guard';
import { TimeGuard } from '@shared-guards/time.guard';
// import { ExecGuard } from '@shared-guards/exec.guard';
// import { ManagerGuard } from '@shared-guards/manager.guard';

const routes: Routes = [
	{ path: '', redirectTo: '/dashboard', pathMatch: 'full' },
	{ path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [DashboardGuard]},
	{ path: 'telework', loadChildren: () => import('./telework/telework.module').then(m => m.TeleworkModule) },
	{ path: 'time', loadChildren: () => import('./time/time.module').then(m => m.TimeModule), canActivate: [TimeGuard] },
	{ path: 'where-am-i', loadChildren: () => import('./where-am-i/where-am-i.module').then(m => m.WhereAmIModule) },
	{ path: 'style-guide', loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuideModule) },
	{ path: 'version-history', loadChildren: () => import('./release-notes/release-notes.module').then(m => m.StyleGuideModule) },
	{ path: '**', redirectTo: '/dashboard' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
