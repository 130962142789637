import { Component, OnInit } from '@angular/core';
import { UserService } from '@shared-services/user.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-nav-bar',
	templateUrl: './nav-bar.component.html'
})
export class NavBarComponent implements OnInit {

	userStatus: any;
	userInfo: any;
	showDashboard = environment.showDashboard;
	showTime = environment.showTime;
	showWhereAmI = environment.showWhereAmI;

	constructor( private userService: UserService ) {
		this.userService.userStatus.subscribe( (userStatus) => this.userStatus = userStatus );
		this.userService.userInfo.subscribe( (userInfo) => this.userInfo = userInfo );
	}

	ngOnInit(): void {}

}


