import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthConfig } from 'angular-oauth2-oidc';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
	issuer: 'https://fs1.tax.doj.gov/adfs', // Url of the Identity Provider
	redirectUri: window.location.origin + '/index.html',  // URL of the SPA to redirect the user to after login
	clientId: 'a93126ec-4950-4f1c-8989-4e379241d483', // The SPA's id. The SPA is registered with this id at the auth-server
	scope: 'oidc email', // set the scope for the permissions the client should request.  The first three are defined by OIDC. The 4th is a usecase-specific one
	showDebugInformation: true,
}

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private authToken: string = '';
	private isLoggedIn: boolean = false;
	// private acceptedDisclaimer: boolean = false;
	env = environment;

	constructor( private httpClient: HttpClient, private oauthService: OAuthService ) {}

	login(token: string) {
		this.setAuthToken(token);
	}

	logout() {
		this.setAuthToken('');
		this.isLoggedIn = false;
		// other logout stuff?  Remove token from storage
	}

	getAuthToken() {
		if (this.env.production) {
			return this.authToken = window.sessionStorage.getItem('id_token') ?? '';
		} else {
			return this.authToken;
		}
	}

	setAuthToken(token: string) {
		this.authToken = token;
		this.isLoggedIn = true;
	}

	getIsLoggedIn() {
		return this.isLoggedIn;
	}

	getDevToken(): Observable<any> {
		return new Observable((subscriber) => {
			const headers = {'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth', 'Content-Type': 'application/x-amz-json-1.1' };
			const body = {
				"AuthParameters": {
					// "USERNAME": "John.Doe@tax.usdoj.gov", // Submitted
					// "USERNAME": "Joe.Blogg@tax.usdoj.gov", // Submitted
					// "USERNAME": "jane.smith@tax.usdoj.gov", // Returned
					"USERNAME": "badamia@amazon.com", // mid level manager
					"PASSWORD": "D0J-tax-c0gnito-badamia"
					// "USERNAME": "kraig@doublehelixcreative.com", // executive level
					// "PASSWORD": "D0J-tax-c0gnito-kraig",
					// "USERNAME": "kdewhirst@ftc-llc.com", // employee level
					// "PASSWORD": "D0J-tax-c0gnito-kdewhirst",
					// "USERNAME": "khamady@ftc-llc.com", // employee level
					// "PASSWORD": "D0J-tax-c0gnito-khamady",

					// "USERNAME": "richard.sharpe@usdoj.gov", // Ineligible
					// "USERNAME": "Jason.Bourne@tax.usdoj.gov", // Ineligible
					// "USERNAME": "Sherlock.Holmes@tax.usdoj.gov", // Returned
				},
				"AuthFlow": "USER_PASSWORD_AUTH",
				"ClientId": "6eteije9vbm9a5stut0i01nv22"
			};
			this.httpClient.post('https://cognito-idp.us-east-1.amazonaws.com/', body, {headers}).subscribe( (response: any) => {
					this.login(response.AuthenticationResult.IdToken);
					subscriber.complete();
				},
				error => console.log(error)
			);
		});
	}

	getProdToken(): Observable<any> {
		return new Observable((subscriber) => {
			this.oauthService.configure(authConfig);
			this.oauthService.tokenValidationHandler = new JwksValidationHandler();
			this.oauthService.loadDiscoveryDocumentAndLogin().then( () => subscriber.complete() );
		});
	}
}
