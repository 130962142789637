import { Component } from '@angular/core';

@Component({
	selector: 'app-report-section-layout',
	templateUrl: './report-section-layout.component.html',
	styleUrls: ['report-section-layout.component.scss']
})
export class ReportSectionLayoutComponent {
	expanded = false;
}
