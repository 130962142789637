import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class HttpLambdaService {
	apiRoot = environment.teleworkEndpoint;
	httpOptions: object = {};
	claimKey: string = '';
	env = environment;

	constructor( private http: HttpClient, private authService: AuthService ) {	}

	getHttp(url: string): Observable<HttpResponse<any>> {
		return this.http
			.get<any>(this.apiRoot + url, this.buildHeaders())
			.pipe(retry(2), catchError(this.handleError('getHttp', [])));
	}

	postHttp(url: string, payload: any): Observable<HttpResponse<any>> {
		return this.http
			.post<any>(this.apiRoot + url, payload, this.buildHeaders())
			.pipe(retry(2), catchError(this.handleError('postHttp')));
	}

	putHttp(url: string, payload: any): Observable<HttpResponse<any>> {
		return this.http
			.put<any>(this.apiRoot + url, payload, this.buildHeaders())
			.pipe(retry(2), catchError(this.handleError('postHttp')));
	}

	deleteHttp(url: string, uid: string): Observable<HttpResponse<any>> {
		return this.http
			.delete<any>(`${this.apiRoot}${url}/${uid}` , this.buildHeaders())
			.pipe(retry(2), catchError(this.handleError('postHttp')));
	}

	private buildHeaders() {
		let auth = this.env.production ? `Bearer ${this.authService.getAuthToken()}` : this.authService.getAuthToken();
		if (this.env.production) {
			const options: object = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': auth,
					'x-apigw-api-id': '95sbmyy3o7'
				}),
				observe: 'response'
			};
			return options;
		} else {
			const options: object = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': auth,
				}),
				observe: 'response'
			};
			return options;
		}
	}

	private handleError(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			console.error(error); // send the error to remote logging infrastructure // log to console instead
			this.log(`${operation} failed: ${error.message}`); // better job of transforming error for user consumption
			return of(result as any); // Let the app keep running by returning an empty result.
		};
	}

	private log(message: string) {
		// this.messageService.add(`HttpService: ${message}`);
	}
}
