import { Component, HostBinding, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from '@shared-services/toast.service';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	host: {'class': 'toast-container position-fixed top-0 end-0 p-3', 'style': 'z-index: 1200'}
})
export class ToastComponent implements OnInit {
	@HostBinding('class.ngb-toasts') get toasts() { return true; }

	constructor(public toastService: ToastService) {}

	ngOnInit() {}

	isTemplate(toast: any) {
		return toast.textOrTemplate instanceof TemplateRef;
	}
}
