<div class="container report-section">
	<div class="row mb-4">
		<div class="col">
			<ng-content select=".section-header"></ng-content>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<ng-content></ng-content>
		</div>
	</div>
</div>
