import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpLambdaService } from './http-lambda.service';
import { ToastService } from './toast.service';

@Injectable({
	providedIn: 'root',
})
export class UserService {

	private managerAllowExtraDays!: boolean;
	private managerAllowAllDays!: boolean;
	private currentUserStatus = {
		isManager: false,
		isExec: false
	};
	authorizationReturned = false;
	userInfo = new BehaviorSubject<any>(null);
	userStatus = new BehaviorSubject<any>(this.currentUserStatus);
	userAllowedExtraDays = new BehaviorSubject<any>(this.currentUserStatus);
	userAllowedAllDays = new BehaviorSubject<any>(this.currentUserStatus);

	constructor(private httpLambda: HttpLambdaService, private toastService: ToastService) {}

	initUserStatus() {
		this.httpLambda.getHttp('/supervisory-status').subscribe(response => {
			if ( response && response.status === 200 && response.body.success === true ) {
				const userStatus = response.body.payload.executive ?
					{ isManager: true, isExec: true }
					: { isManager: true, isExec: false };
				this.authorizationReturned = true;
				this.setUserStatus(userStatus);
			} else {
				this.authorizationReturned = true;
				this.setUserStatus({ isManager: false, isExec: false });
			}
			// this.setUserStatus({ isManager: true, isExec: true }); // ! TODO Revert this testing change by removing the line
		});

	}

	initUserInfo() {
		this.httpLambda.getHttp('/employee').subscribe(response => {
			if ( response && response.status === 200 && response.body.success === true ) {
				this.userInfo.next(response.body.payload);
			} else {
				this.toastService.show('There was an issue finding your information. Please contact the help desk or refresh the page to try again.', { classname: 'bg-danger text-light' });
			}
		});
	}

	setUserStatus(userStatus: any) {
		this.currentUserStatus = userStatus;
		this.userStatus.next(this.currentUserStatus);
	}

	getUserStatus() {
		return this.currentUserStatus;
	}

	setManagerAllowExtraDays(managerAllowExtraDays: boolean) {
		this.managerAllowExtraDays = managerAllowExtraDays;
		this.userAllowedExtraDays.next(this.managerAllowExtraDays);
	}

	getManagerAllowExtraDays() {
		return this.managerAllowExtraDays;
	}

	setManagerAllowAllDays(managerAllowAllDays: boolean) {
		this.managerAllowAllDays = managerAllowAllDays;
		this.userAllowedAllDays.next(this.managerAllowAllDays);
	}

	getManagerAllowAllDays() {
		return this.managerAllowAllDays;
	}
}
