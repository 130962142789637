import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';

declare var $: any;
@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit, AfterViewInit{
	@Input() type: string = '';
	@Output() alertClosed: EventEmitter<any> = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
	}

	closed(event: any): void {
		this.alertClosed.emit(event);
	}

}
