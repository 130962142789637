import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mask',
})
export class NumberMaskPipe implements PipeTransform {
	transform(inputNumber: string): string | null {
		const visibleDigits = 4;
		if(inputNumber) {
			const maskedSection = inputNumber.slice(0, -visibleDigits);
			const visibleSection = inputNumber.slice(-visibleDigits);
			return maskedSection.replace(/./g, '*') + visibleSection;
		} else {
			return null;
		}
	}
}
