import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '@shared-services/loader.service';

@Component({
	selector: 'app-spinner',
	styleUrls: ['spinner.component.scss'],
	template: `
		<!-- <div *ngIf="isLoading | async" class="spinner-container"> -->
			<div class="spinner spinner-border" style="width: 3rem; height: 3rem;" role="status">
  				<span class="sr-only">Loading...</span>
			</div>
		<!-- </div> -->`
})
export class SpinnerComponent {
	isLoading: Subject<boolean> = this.loaderService.isLoading;

	constructor(private loaderService: LoaderService) {}
}
