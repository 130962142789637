import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NumberMaskPipe } from './number-mask.pipe';
import { PhoneFormatPipe } from './phone-format.pipe';


@NgModule({
	declarations: [
		NumberMaskPipe,
		PhoneFormatPipe
	],
	imports: [
		CommonModule
	],
	exports: [
		NumberMaskPipe,
		PhoneFormatPipe
	]
})

export class SharedPipesModule { }
