<nav class="navbar navbar-expand-lg navbar-dark bg-primary navbar-main">
	<div class="container">
		<a class="navbar-brand" href="#" routerLink="/home">
			<img src="assets/img/logo-new-tax.png" alt="The United States Department of Justice">
		</a>
		<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav me-auto mb-2 mb-lg-0">
				<li class="nav-item" routerLinkActive="active"  [routerLinkActiveOptions]="{exact:false}" *ngIf="showDashboard">
					<a class="nav-link" href="#" routerLink="/dashboard">Dashboard</a>
				</li>
				<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
					<a class="nav-link" href="#" routerLink="/telework">Telework</a>
				</li>
				<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"  *ngIf="showWhereAmI">
					<a class="nav-link" href="#" routerLink="/where-am-i">Where Am I</a>
				</li>
				<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"  *ngIf="showTime">
					<a class="nav-link" href="#" routerLink="/time">Time</a>
				</li>
			</ul>
			<span class="text-light">
				{{ userInfo?.firstName }} {{ userInfo?.lastName }}
			</span>
		</div>
	</div>
</nav>
